<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Project List</h4>
      </CCol>

      <CCol sm="8"></CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <CRow>
                <CCol sm="8">
                  <form v-on:submit.prevent="filterProject(filter)">
                    <CRow>
                      <CCol sm="4" class="pr-0">
                        <CInput
                            v-model="filter.keyword"
                            placeholder="Search..."
                        />
                      </CCol>

                      <CCol sm="2">
                        <button type="submit" class="btn btn-success w-100">Search</button>
                      </CCol>
                    </CRow>
                  </form>
                </CCol>
                <CCol sm="4" class="text-right">
                  <router-link v-if="checkUsrPermissions(['Project_all', 'Project_create'])"
                               :to="{name:'CreateProject'}" class="btn btn-primary float-right">
                    <i class="fa fa-plus"></i> Create Project
                  </router-link>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">SL</th>
                    <TableColumn name="Name" sort="name" align="center" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                    <TableColumn name="Project Lead" align="center" sort="project_id" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                    <th scope="col">Supervisor</th>
                    <th scope="col">Description</th>
                    <th scope="col">Total Meters</th>
                    <th scope="col">Deadline</th>
                    <th
                        scope="col"
                        v-if="checkUsrPermissions(['Project_all', 'Project_edit'])"
                    >
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="projects.length">
                  <tr
                      class="text-center"
                      v-for="(project, index) in projects"
                      :key="project.id"
                  >
                    <td scope="row">{{ meta.from + index }}</td>
                    <td scope="row">{{ project.name }}</td>

                    <td scope="row">{{
                        project.project_lead ? project.project_lead.first_name
                            + " "
                            + project.project_lead.last_name : ""
                      }}
                    </td>
                    <td scope="row">{{
                        project.supervisor ? project.supervisor.first_name
                            + " "
                            + project.supervisor.last_name : ""
                      }}
                    </td>
                    <td scope="row">{{ project.description }}</td>
                    <td scope="row">{{ project.total_meters }}</td>
                    <td scope="row">{{ project.deadline | dateFormat}}</td>
                    <td
                        scope="row"
                        v-if="checkUsrPermissions(['Project_all', 'Project_edit'])"
                    >
                      <router-link :to="{name:'EditProject', params:{id:project.id}}"
                                   v-if="checkUsrPermissions(['Project_all', 'Project_edit'])"
                      >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            fill="#5e76e7"
                            width="32"
                            height="22"
                            version="1.1"
                            id="lni_lni-pencil-alt"
                            x="0px"
                            y="0px"
                            viewBox="0 0 64 64"
                            style="enable-background: new 0 0 64 64"
                            xml:space="preserve"
                        >
                            <path
                                d="M62.7,11.2c0-0.7-0.3-1.3-0.8-1.8c-1.3-1.3-2.5-2.5-3.7-3.7c-1.1-1.1-2.2-2.2-3.3-3.4c-0.4-0.5-1-0.9-1.6-1  c-0.7-0.1-1.5,0.1-2.1,0.6l-7.2,7.2H8.7c-4.1,0-7.4,3.3-7.4,7.4v38.9c0,4.1,3.3,7.4,7.4,7.4h38.9c4.1,0,7.4-3.3,7.4-7.4V19.9  l6.9-6.9C62.4,12.5,62.7,11.8,62.7,11.2z M33.3,36.6c-0.1,0.1-0.3,0.2-0.4,0.3l-8.6,2.9l2.8-8.6c0.1-0.2,0.1-0.3,0.3-0.4l19-19  l6,5.9L33.3,36.6z M51.5,55.4c0,2.1-1.7,3.9-3.9,3.9H8.7c-2.1,0-3.9-1.7-3.9-3.9V16.4c0-2.1,1.7-3.9,3.9-3.9h31.9L24.9,28.2  c-0.5,0.5-0.9,1.1-1.1,1.8l-3.8,11.6c-0.2,0.6-0.1,1.2,0.2,1.7c0.3,0.4,0.7,0.8,1.6,0.8h0.3l11.9-3.9c0.7-0.2,1.3-0.6,1.8-1.1  l15.8-15.7V55.4z M54.8,15.1l-6-5.9l4-4c1,1,1.9,1.9,2.9,2.9c1,1,2,2,3,3.1L54.8,15.1z"
                            />
                          </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="7">
                      <h5 class="text-center">Data Not Available.</h5>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
              v-if="projects.length"
              align="center"
              :pages="rows"
              :active-page.sync="currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TableColumn from "@/components/TableColumn.vue";

export default {
  components: {TableColumn},
  data: () => {
    return {
      filter: {
        currentPage: 1,
        keyword: '',
        sortBy: "id",
        sortOrder: "desc",
      }
    };
  },
  methods: {
    changeSort(event) {
      this.filter.sortBy = event[0];
      this.filter.sortOrder = event[1];
      this.$store.dispatch("Projects/getProjects", this.filter);
    },
    filterProject(search) {
      if (search) {
        search.filter.currentPage = 1;
        // this.$router.replace({name: "Projects", query: search});
        this.$store.dispatch("Projects/getProjects", search);
      }
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$store.dispatch("Projects/getProjects", this.filter);
    }
  },
  computed: {
    ...mapGetters("Projects", ["projects", "rows", "perPage", "meta"]),
  },
  mounted() {
    this.$store.dispatch("Projects/getProjects", this.filter );
  },
};
</script>
<style scoped>
.hide-footer >>> footer {
  display: none;
}

.card {
  border: none;
  padding: 15px 15px 0;
}

.custom-close-button >>> button {
  color: aliceblue;
}
</style>
<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }

          a {
            color: black;
          }

          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.role {
  font-size: 11px;
  margin: 4px 4px;
  font-weight: 100;
}
</style>
